import { useAdditionalActions } from "hooks/useAdditionalActions"
import { useReturn } from "hooks/useReturn"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Uuid } from "types"
import api from "../../helpers/api"
import { useNotifications } from "../../hooks/useNotifications"
import { SAVE_FOR_TEMP } from "../../store/bufferTemp/actions"
import { SET_ERRORS } from "../../store/errors/actions"
import { RESET_CURRENT_VALUES, SET_FIELDS } from "../../store/fields/actions"
import { SET_IS_SEARCH_RESULT } from "../../store/isSearchResult/actions"
import { SaveMenuProps } from "../../types/interfaces"
import Button from "./Button"
import { transformSendData } from "./SaveMenu.tranformers"

export const SaveMenu: React.FC<SaveMenuProps> = (props) => {
    let currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues
    )
    const [loading, setLoading] = useState<"apply" | "save" | null>(null)

    const { onSave } = useAdditionalActions()

    const { showNotification } = useNotifications()

    const { returnLink } = useReturn()

    const onModify = props.onModify
    const groupsDoNotMerge = props.groupsDoNotMerge || []

    const clonedCurrentValues = JSON.parse(JSON.stringify(currentValues))

    const dispatch = useDispatch()

    const routerHistory = useHistory()
    const routerParams: { id?: Uuid | undefined } = useParams()

    const isCreatePage = !!routerHistory.location.pathname.match(/create$/)

    const sendData = (
        data: {},
        isSave: boolean,
        url: string,
        loading: "apply" | "save"
    ) => {
        setLoading(loading)
        const groupMerge = (stateData: any) => {
            let data: { [key: string]: any } = {}

            for (const group in stateData) {
                if (
                    stateData[group] &&
                    groupsDoNotMerge.indexOf(group) !== -1
                ) {
                    data[group] = { ...stateData[group] }
                } else if (stateData[group]) {
                    data = { ...data, ...stateData[group] }

                    if (data.password && !isCreatePage) {
                        data.newPassword = data.password
                    }
                }
            }
            return data
        }

        data = groupMerge(data)

        if (onModify) onModify(data)

        const requestData = transformSendData(data) as any

        if (requestData.measureUnit) {
            requestData.measureUnit = requestData.measureUnit?.value
        }

        const promise = !routerParams.id
            ? api.post(props.v1Url ? props.v1Url.post : url, {}, requestData)
            : api.put(
                  `${props.v1Url ? props.v1Url.put : url}/${routerParams.id}`,
                  {},
                  requestData
              )

        promise
            .then((response) => {
                return onSave(response.data as any)
            })
            .then(() => {
                if (isSave) {
                    showNotification({
                        type: "success",
                        message: isCreatePage
                            ? "Успешно создан!"
                            : "Успешно обновлен!",
                        cb: () => {
                            dispatch(SET_ERRORS([]))
                            routerHistory.goBack()
                        },
                    })
                } else {
                    showNotification({
                        type: "success",
                        message: "Успешно!",
                        cb: () => {
                            dispatch(SET_ERRORS([]))
                        },
                    })
                }
            })
            .catch((err) => {
                dispatch(SET_ERRORS(err.response.data || []))
                if (err.response.status >= 500) {
                    window.dispatchEvent(new Event("FatalError"))
                    return
                }
                showNotification({
                    type: "danger",
                    message: err.response.data.errors
                        ? err.response.data.errors?.[0]
                        : "Ошибка обновления. Проверьте основные поля",
                })
            })
            .finally(() => {
                setLoading(null)
            })
    }

    const resetStates = () => {
        dispatch(SET_FIELDS([]))
        dispatch(RESET_CURRENT_VALUES())
        dispatch(SET_ERRORS([]))
        dispatch(SET_IS_SEARCH_RESULT(false))
        dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "", q: "" }))
        // routerHistory.goBack()
    }

    return (
        <div
            className="btn-toolbar justify-content-between align-items-center users-toolbar"
            role="toolbar"
            aria-label="Панель инструментов с группами кнопок"
        >
            <Link
                to={returnLink}
                className="btn-toolbar-back"
                onClick={resetStates}
            >
                <i className="btn-toolbar-back__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-left-short"
                        viewBox="0 0 16 16"
                    >
                        <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                </i>
                Назад в список
            </Link>
            <div className="btn-toolbar-group">
                <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                >
                    {/* <button type="button" className="btn btn-outline-primary">Копировать</button> */}
                    <Button
                        disabled={!!loading}
                        isLoading={loading === "apply"}
                        onClick={
                            Object.keys(clonedCurrentValues).length !== 0
                                ? () =>
                                      sendData(
                                          clonedCurrentValues,
                                          false,
                                          props.url,
                                          "apply"
                                      )
                                : () => {}
                        }
                        type="button"
                        className="btn btn-outline-primary"
                    >
                        Применить
                    </Button>
                    <Button
                        disabled={!!loading}
                        isLoading={loading === "save"}
                        onClick={
                            Object.keys(clonedCurrentValues).length !== 0
                                ? () =>
                                      sendData(
                                          clonedCurrentValues,
                                          true,
                                          props.url,
                                          "save"
                                      )
                                : () => {}
                        }
                        type="button"
                        className="btn btn-primary"
                    >
                        Сохранить
                    </Button>
                </div>
            </div>
        </div>
    )
}
