// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperForAsyncSelect_wrapperSelect__imRDT {
  display: flex;
  align-items: center;
  gap: 24px;
}
.wrapperForAsyncSelect_wrapperSelect__imRDT > label {
  max-width: 132px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
}
.wrapperForAsyncSelect_wrapperSelect__imRDT > :nth-child(2) {
  max-width: 338px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/WrapperForAsyncSelect/wrapperForAsyncSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;AACE;EACE,gBAAA;EACA,WAAA;EAEA,eAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,WAAA;AADJ","sourcesContent":[".wrapperSelect {\n  display: flex;\n  align-items: center;\n  gap: 24px;\n\n  & > label {\n    max-width: 132px;\n    width: 100%;\n\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 20.8px;\n  }\n\n  &>:nth-child(2) {\n    max-width: 338px;\n    width: 100%;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperSelect": `wrapperForAsyncSelect_wrapperSelect__imRDT`
};
export default ___CSS_LOADER_EXPORT___;
