import AccordionContainer from "components/UI/AccordionContainer"
import { Flex } from "components/UI/Flex"
import { FC } from "react"

interface IItemsAccordion {
    items: any[]
    isFetched: boolean
}

const ItemsAccordion: FC<IItemsAccordion> = ({ isFetched, items }) => {
    return (
        <Flex.Col className="control-in" align="start" fullWidth>
            {!isFetched ? (
                "Загрузка..."
            ) : !items.length ? (
                "В номенклатурной группе серии не найдены"
            ) : (
                <AccordionContainer items={items} />
            )}
        </Flex.Col>
    )
}

export default ItemsAccordion
