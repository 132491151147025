import { AsyncSelect } from "components/UI"
import React from "react"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"
import { useStoreEntities } from "./useStoreEntities"

const InputProducerSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const { producer, producerIsDisabled, getProducerParams, setProducer } =
        useStoreEntities(group)

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <AsyncSelect
                        value={producer}
                        onChange={setProducer}
                        name={propertyContainer.getName()}
                        aria-label="Default select"
                        id={propertyContainer.getName()}
                        isClearable
                        dataUrl="producer"
                        isDisabled={producerIsDisabled}
                        params={getProducerParams}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputProducerSelect
