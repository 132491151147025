import { SelectEntity } from "components/Selects/types"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DELETE_FIELDS_VALUES, SET_CURRENT_VALUES } from "store/fields/actions"
import { IdNameItem } from "types"
import { Field } from "types/api"

type Deps = "series" | "itemsGroup" | "producer"

const transformToIdName = (value: SelectEntity | null): IdNameItem | null =>
    value ? { id: value.value, name: value.label } : value
const transformToSelectEntity = (
    value: IdNameItem | null
): SelectEntity | null =>
    value ? { value: value.id, label: value.name } : value

export const useStoreEntities = (group: string) => {
    const fields = useSelector((state: any) => {
        return state.fields
    })

    const dispatch = useDispatch()

    const initFields: Field[] = fields.initFields
    const currentValues: Record<string, any> = fields.currentValues
    const values = currentValues?.[group] ?? ({} as any)

    const itemsGroup = values.itemsGroup
        ? transformToSelectEntity(values.itemsGroup)
        : undefined
    const itemsGroupId =
        values.itemsGroup?.id ?? values.itemsGroup?.code ?? values.itemsGroup

    const producer = values.producer
        ? transformToSelectEntity(values.producer)
        : undefined
    const producerId =
        values.producer?.id ?? values.producer?.code ?? values.producer

    const series = values.series
        ? transformToSelectEntity(values.series)
        : undefined
    const seriesId = values.series?.id ?? values.series?.code ?? values.series

    const model = values.model
        ? transformToSelectEntity(values.model)
        : undefined

    console.log(values.model)

    const dependencies = useMemo(() => {
        return {
            series: initFields?.some((el) => el.name === "series") ?? false,
            producer: initFields?.some((el) => el.name === "producer") ?? false,
            itemsGroup:
                initFields?.some((el) => el.name === "itemsGroup") ?? false,
        }
    }, [initFields])

    const selected = useMemo(() => {
        const values = currentValues?.[group] ?? {}
        return {
            series: !!values.series,
            producer: !!values.producer,
            itemsGroup: !!values.itemsGroup,
        }
    }, [currentValues])

    const setItemsGroup = useCallback(
        (value: SelectEntity | null) => {
            dispatch(
                SET_CURRENT_VALUES(
                    { itemsGroup: transformToIdName(value) },
                    group
                )
            )
            dispatch(
                DELETE_FIELDS_VALUES({
                    fields: ["series", "producer", "model"],
                    group,
                })
            )
        },
        [dispatch, SET_CURRENT_VALUES, DELETE_FIELDS_VALUES, group]
    )

    const setProducer = useCallback(
        (value: SelectEntity | null) => {
            dispatch(
                SET_CURRENT_VALUES(
                    { producer: transformToIdName(value) },
                    group
                )
            )
            dispatch(
                DELETE_FIELDS_VALUES({ fields: ["series", "model"], group })
            )
        },
        [dispatch, SET_CURRENT_VALUES, DELETE_FIELDS_VALUES, group]
    )

    const setSeries = useCallback(
        (value: SelectEntity | null) => {
            dispatch(
                SET_CURRENT_VALUES({ series: transformToIdName(value) }, group)
            )
            dispatch(DELETE_FIELDS_VALUES({ fields: ["model"], group }))
        },
        [dispatch, SET_CURRENT_VALUES, DELETE_FIELDS_VALUES, group]
    )

    const setModel = useCallback(
        (value: SelectEntity | null) => {
            console.log(`value`, value)
            dispatch(
                SET_CURRENT_VALUES({ model: transformToIdName(value) }, group)
            )
        },
        [dispatch, SET_CURRENT_VALUES, group]
    )

    const modelIsDisabled = useMemo(() => {
        const keys: Deps[] = ["itemsGroup", "producer", "series"]
        return keys.some((el) => {
            return dependencies[el] && !selected[el]
        })
    }, [dependencies, selected])

    const seriesIsDisabled = useMemo(() => {
        const keys: Deps[] = ["itemsGroup", "producer"]
        return keys.some((el) => {
            return dependencies[el] && !selected[el]
        })
    }, [dependencies, selected])

    const producerIsDisabled = useMemo(() => {
        const keys: Deps[] = ["itemsGroup"]
        return keys.some((el) => {
            return dependencies[el] && !selected[el]
        })
    }, [dependencies, selected])

    const getModelParams = useMemo(() => {
        return {
            itemsGroup: dependencies.itemsGroup ? itemsGroupId : undefined,
            producer: dependencies.producer ? producerId : undefined,
            series: dependencies.series ? seriesId : undefined,
        }
    }, [dependencies, itemsGroupId, producerId, seriesId])

    const getSeriesParams = useMemo(() => {
        return {
            itemsGroup: dependencies.itemsGroup ? itemsGroupId : undefined,
            producer: dependencies.producer ? producerId : undefined,
        }
    }, [dependencies, itemsGroupId, producerId])

    const getProducerParams = useMemo(() => {
        return {
            itemsGroup: dependencies.itemsGroup ? itemsGroupId : undefined,
        }
    }, [dependencies, itemsGroupId])

    return {
        modelIsDisabled,
        seriesIsDisabled,
        producerIsDisabled,
        setItemsGroup,
        setProducer,
        setModel,
        setSeries,
        getModelParams,
        getProducerParams,
        getSeriesParams,
        model,
        producer,
        itemsGroup,
        series,
    }
}
