// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actionButtons_formButton__1aZcx {
  white-space: normal;
  height: auto;
  display: flex;
  gap: 8px;
  max-width: 370px;
  width: 100%;
}

.actionButtons_copyButton__JQJmr {
  min-width: 370px;
}`, "",{"version":3,"sources":["webpack://./src/components/NamingSelectWithActions/actionButtons.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".formButton {\n    white-space: normal;\n    height: auto;\n    display: flex;\n    gap: 8px;\n    max-width: 370px;\n    width: 100%;\n}\n\n.copyButton {\n    min-width: 370px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formButton": `actionButtons_formButton__1aZcx`,
	"copyButton": `actionButtons_copyButton__JQJmr`
};
export default ___CSS_LOADER_EXPORT___;
