import { Input, Modal } from "antd"
import api from "helpers/api"
import { useModalProps } from "hooks/useModalProps"
import { useNotifications } from "hooks/useNotifications"
import { FC, useState } from "react"
import { Uuid } from "types"
import { useConfiguratorStore } from "./GeneralConfiguratorWrapper"

type CreateEmptyNomenclatureRequest = {
    name: string
    accountingName: string
    series?: Uuid
    model?: Uuid
    producer?: Uuid
    itemsGroup?: Uuid
}

export const ManutalCreateNomenclature: FC<{
    disabled: boolean
}> = ({ disabled }) => {
    const { open, showModal, hideModal } = useModalProps()

    const { activeGroup, activeModel, activeProducer, activeSeries } =
        useConfiguratorStore()

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const { showSuccess, handleApiError } = useNotifications()

    const save = () => {
        setLoading(true)
        api.post<CreateEmptyNomenclatureRequest, { id: Uuid }>(
            "v1/nomenclature/create/empty",
            {},
            {
                name,
                series: activeSeries?.value,
                model: activeModel?.value,
                itemsGroup: activeGroup?.value,
                producer: activeProducer?.value,
                accountingName: name,
            }
        )
            .then((response) => {
                showSuccess()
                hideModal()
                const id = response.data?.id
                window.open("/nomenclature/" + id)
            })
            .catch(handleApiError)
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <button
                className="btn btn-primary ms-3"
                disabled={disabled}
                onClick={showModal}
            >
                Создать номенклатуру без свойств
            </button>
            <Modal
                open={open}
                onCancel={hideModal}
                onOk={save}
                title="Создать номенклатуру вручную"
                okText="Создать номенклатурную позицию"
                okButtonProps={{ loading, size: "large" }}
                cancelButtonProps={{ size: "large" }}
            >
                <Input
                    size="large"
                    className="fw"
                    placeholder="Наименование"
                    onChange={(e) => setName(e.target.value)}
                />
            </Modal>
        </>
    )
}
