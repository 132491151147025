import { useCallback, useState } from "react"

export const useModalProps = () => {
    const [open, setOpen] = useState(false)

    const hideModal = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const showModal = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    return {
        open,
        hideModal,
        showModal,
    }
}
