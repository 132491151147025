// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordionContainer_accordions__s7P0u {
  background: #ffffff;
  border-radius: 0px;
  width: 100%;
}
.accordionContainer_accordions__s7P0u .accordionContainer_item__ILRcy {
  margin-bottom: 0;
  border-top: "1px solid #CCCCCC";
}
.accordionContainer_accordions__s7P0u .accordionContainer_item__ILRcy .accordionContainer_itemContent__J0ron > span {
  font-size: 16;
  font-weight: 400;
  min-width: 200px;
}
.accordionContainer_accordions__s7P0u .accordionContainer_item__ILRcy .accordionContainer_itemContent__J0ron button {
  height: -moz-fit-content;
  height: fit-content;
}
.accordionContainer_accordions__s7P0u .accordionContainer_item__ILRcy .accordionContainer_itemContent__J0ron .accordionContainer_extraClose__dCeZt {
  color: #707070;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/AccordionContainer/accordionContainer.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AACI;EACI,gBAAA;EACA,+BAAA;AACR;AAEY;EACI,aAAA;EACA,gBAAA;EACA,gBAAA;AAAhB;AAGY;EACI,wBAAA;EAAA,mBAAA;AADhB;AAIY;EACI,cAAA;AAFhB","sourcesContent":[".accordions {\n    background: #ffffff;\n    border-radius: 0px;\n    width: 100%;\n\n    .item {\n        margin-bottom: 0;\n        border-top: \"1px solid #CCCCCC\";\n\n        .itemContent {\n            & > span {\n                font-size: 16;\n                font-weight: 400;\n                min-width: 200px;\n            }\n\n            button {\n                height: fit-content;\n            }\n\n            .extraClose {\n                color: #707070;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordions": `accordionContainer_accordions__s7P0u`,
	"item": `accordionContainer_item__ILRcy`,
	"itemContent": `accordionContainer_itemContent__J0ron`,
	"extraClose": `accordionContainer_extraClose__dCeZt`
};
export default ___CSS_LOADER_EXPORT___;
