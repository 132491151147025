// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nameGenerationSettings_card__OqV-k {
  width: 100%;
  max-width: 1300px;
}
.nameGenerationSettings_card__OqV-k > div {
  width: 100%;
}
.nameGenerationSettings_card__OqV-k > div h5 {
  margin-bottom: 0;
  font-weight: 500;
}
.nameGenerationSettings_card__OqV-k > div > div {
  width: 100%;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/NameGenerationSettings/nameGenerationSettings.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ;AACI;EACI,WAAA;AACR;AAAQ;EACI,gBAAA;EACA,gBAAA;AAEZ;AACQ;EACI,WAAA;EACA,gBAAA;AACZ","sourcesContent":[".card {\n    width: 100%;\n    max-width: 1300px;\n\n    & > div {\n        width: 100%;\n        h5 {\n            margin-bottom: 0;\n            font-weight: 500;\n        }\n\n        & > div {\n            width: 100%;\n            margin-bottom: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `nameGenerationSettings_card__OqV-k`
};
export default ___CSS_LOADER_EXPORT___;
