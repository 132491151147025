// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectionProducersAndGroups_container__dcs8y > div {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/NameGenerationSettings/SelectionProducersAndGroups/selectionProducersAndGroups.module.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR","sourcesContent":[".container {\n    & > div {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `selectionProducersAndGroups_container__dcs8y`
};
export default ___CSS_LOADER_EXPORT___;
