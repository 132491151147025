// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordionButtons_button__d7Zh2 {
  padding: 0;
  height: "fit-content";
}`, "",{"version":3,"sources":["webpack://./src/pages/NameGenerationSettings/components/AccordionButtons/accordionButtons.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,qBAAA;AACJ","sourcesContent":[".button {\n    padding: 0;\n    height: \"fit-content\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `accordionButtons_button__d7Zh2`
};
export default ___CSS_LOADER_EXPORT___;
