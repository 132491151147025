import { useQuery } from "@tanstack/react-query"
import { Button, Card, Typography } from "antd"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import UNumberItem from "components/UNumberItem"
import { UTable } from "components/UTable"
import { TableCol } from "components/UTable/types"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useEffect, useMemo, useState } from "react"
import { Uuid } from "types"
import { NomenclatureItem } from "types/api"

type ShortNomenclatureItem = [Uuid, string]

type Pagination = {
    limit: number
    offset: number
    total_count: number
}

type ShortNomenclatureResponse = {
    items: ShortNomenclatureItem[]
} & Pagination

enum PriceUuid {
    rrc = "16b14fd9-9c28-11e4-9dc9-000c29c1b1ad",
    dealer = "45bb93f6-aab3-4a9a-89bf-0e8477b87528",
    none = 'none',
}

const priceTypesData: Record<PriceUuid, string> = {
    [PriceUuid.dealer]: 'Дилер',
    [PriceUuid.rrc]: 'РРЦ',
    [PriceUuid.none]: 'Цена от поставщика'
}

type ChangedPrices = {
    [PriceUuid.rrc]?: number
    [PriceUuid.dealer]?: number
    [PriceUuid.none]?: number
}

type SavePricesData = {
    nomenclatureId: Uuid
    prices: Array<{ currency: string; priceType: Uuid | null; value: number }>
}

export default function NomenclaturePricesTable({
    activeModel,
    activeGroup,
    activeProducer,
    activeSeries,
}: {
    activeModel: SelectEntity | null
    activeGroup: SelectEntity | null
    activeSeries: SelectEntity | null
    activeProducer: SelectEntity | null
}) {
    const { handleApiError, showNotification } = useNotifications()
    const [pagination, setPagination] = useState<Pagination>({
        offset: 0,
        total_count: 1,
        limit: 30,
    })
    const [setPrices, setSetPrices] = useState<Record<Uuid, ChangedPrices>>({})
    const [activeCellNumber, setActiveCellNumber] = useState(0)
    const [tableData, setTableData] = useState<ShortNomenclatureItem[]>([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        setTableData([])
    }, [activeModel, activeSeries])

    const savePrices = () => {
        setSaveLoading(true)
        Promise.all(
            Object.entries(setPrices).map(([nomenclatureId, prices]) => {
                return api.post<SavePricesData, any>(
                    "v1/economics/price-nomenclature-history",
                    {},
                    {
                        nomenclatureId,
                        prices: Object.entries(prices).map(
                            ([priceType, value]) => ({
                                currency: "BYN",
                                priceType: priceType === PriceUuid.none ? null : priceType,
                                value,
                            })
                        ),
                    }
                )
            })
        )
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Сохранено",
                })
            })
            .catch(handleApiError)
            .finally(() => setSaveLoading(false))
    }

    const { isFetching } = useQuery<ShortNomenclatureItem[]>({
        queryKey: [
            "shortNomenclature",
            activeGroup,
            activeModel,
            activeProducer,
            activeSeries,
            searchValue,
            pagination,
        ],
        enabled: [activeGroup, activeProducer, activeSeries].every(Boolean),
        queryFn: () =>
            api
                .getTyped<ShortNomenclatureResponse>(
                    `v1/items-group/${activeGroup?.value}/producer/${activeProducer?.value}/series/${activeSeries?.value}/shortNomenclature`,
                    {
                        limit: pagination.limit,
                        offset: pagination.offset,
                        model: activeModel?.value,
                        search: searchValue || undefined,
                    }
                )
                .then((response) => {
                    setPagination((prev) => ({
                        ...prev,
                        offset: response.offset,
                        total_count: response.total_count,
                        limit: response.limit,
                    }))
                    setTableData((prev) =>
                        response.offset > 0
                            ? [...prev, ...response.items]
                            : response.items
                    )
                    return response.items
                })
                .catch((error) => {
                    handleApiError(error)
                    return []
                }),
        initialData: [],
    })

    const changePrice = (value: number, priceType: PriceUuid, id: Uuid) => {
        console.log('priceType', priceType);
        setSetPrices((prev) => {
            const priceObject = prev[id]
            const newPriceObject = priceObject
                ? { ...priceObject, [priceType]: value }
                : { [priceType]: value }
            return {
                ...prev,
                [id]: newPriceObject,
            }
        })
    }

    const removePrice = (priceType: PriceUuid, id: Uuid) => {
        setSetPrices((prev) => {
            const priceObject = prev[id]
            if (!priceObject) return prev
            delete priceObject[priceType]
            return {
                ...prev,
            }
        })
    }

    const changeActiveCellNumber = (distance: number, negative: boolean) =>
        setActiveCellNumber((prev) => {
            const newValue = prev + (negative ? -distance : distance)
            if (negative) {
                return newValue < 0 ? 0 : newValue
            } else {
                return newValue > tableData.length * 3 - 1
                    ? tableData.length * 3 - 1
                    : newValue
            }
        })

    const navigateProps = {
        onNext: () => changeActiveCellNumber(1, false),
        onPrev: () => changeActiveCellNumber(1, true),
        onDown: () => changeActiveCellNumber(3, false),
        onUp: () => changeActiveCellNumber(3, true),
    }

    if (!activeSeries) return null

    return (
        <Card className="--visible fw">
            <Flex.Col fullWidth gap={10}>
                <Typography.Title level={5}>
                    {`Установка цен на номенклатуру ${activeModel
                        ? `модели ${activeModel.label}`
                        : `серии ${activeSeries.label}`
                        }`}
                </Typography.Title>

                <UTable
                    VIRTUAL_ROW_COUNT={30}
                    height={tableData.length >= 30 ? 700 : undefined}
                    onScroll={() => setActiveCellNumber(-1)}
                    onScrollFinish={() => {
                        if (pagination.offset < pagination.total_count) {
                            setPagination((prev) => ({
                                ...prev,
                                offset: pagination.offset + pagination.limit,
                            }))
                        }
                    }}
                    data={tableData}
                    search
                    onSearch={setSearchValue}
                    loading={isFetching}
                    columns={[
                        {
                            columnName: "Название",
                            render: (row) => row[1],
                            width: 40,
                        },
                        ...(Object.entries(priceTypesData).map(([priceType, columnName], columnId) => ({
                            columnName,
                            render: (row, idx) => (
                                <UNumberItem
                                    className="fw"
                                    noBackground
                                    noFormControl
                                    handleFocus={() => {
                                        setActiveCellNumber(idx * 3 + columnId)
                                    }}
                                    handleBlur={() => {
                                        setActiveCellNumber(-1)
                                    }}
                                    focused={activeCellNumber === idx * 3 + columnId}
                                    floatRank={2}
                                    navigateProps={navigateProps}
                                    value={
                                        setPrices[row[0]]?.[priceType as PriceUuid]
                                    }
                                    onPressEnter={navigateProps.onNext}
                                    handleChange={(v) => {
                                        v
                                            ? changePrice(
                                                v,
                                                priceType as PriceUuid,
                                                row[0]
                                            )
                                            : removePrice(
                                                priceType as PriceUuid,
                                                row[0]
                                            )
                                    }}
                                />
                            ),
                            width: 20,
                        } as TableCol<ShortNomenclatureItem>)))
                    ]}
                />

                <Button
                    disabled={!Object.keys(setPrices).length}
                    onClick={savePrices}
                    loading={saveLoading}
                >
                    Сохранить
                </Button>
            </Flex.Col>
        </Card>
    )
}
