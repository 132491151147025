import { useQuery } from "@tanstack/react-query"
import { InputNumber, Tag, Typography } from "antd"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useDetailData } from "hooks/useDetailData"
import { useNotifications } from "hooks/useNotifications"
import { Attribute } from "pages/CostCollector/CostCollectorAttributes/CostCollectorAttributes.types"
import { FocusEvent, KeyboardEvent, useCallback, useRef, useState } from "react"
import { Uuid } from "types"
import {
    Country,
    ItemsGroupEntity,
    ListingResponse,
    SeriesEnity,
} from "types/api"
import EntityCopyRegion from "./EntityCopyRegion"

export type SeriesCombinationItem = {
    attributeCombination: {
        attributes: Attribute[]
        id: Uuid
    }
    itemsGroup: ItemsGroupEntity
    order: number
    region: {
        country: Country
        id: string
        name: string
        vat: number
    }
    series: SeriesEnity
}

export type CombinationTableItem = {
    id: Uuid
    attributes: Array<{ id: Uuid; name: string }>
}

export default function ProducerDetailCombinationOrder({
    activeGroup,
    activeRegion,
}: {
    activeGroup: SelectEntity
    activeRegion: SelectEntity
}) {
    const { id } = useDetailData()

    const { showNotification, handleApiError } = useNotifications()
    const [selectedRow, setSelectedRow] = useState<CombinationTableItem | null>(
        null
    )

    const focusRef = useRef<number>()

    const {
        data: combinationOrders,
        refetch: refetchCombinatorOrders,
        isFetching: fetchCombinatinOrders,
        dataUpdatedAt,
    } = useQuery<SeriesCombinationItem[]>({
        queryKey: [
            "attribute-combination-order",
            id,
            activeGroup,
            activeRegion,
        ],
        enabled: !!activeGroup?.value && !!activeRegion?.value,
        queryFn: () =>
            api.getTyped<any[]>(
                "v1/economics/producer/attribute-combination-order",
                {
                    producer: id,
                    region: activeRegion?.value,
                    group: activeGroup?.value,
                }
            ),
        initialData: [],
    })

    const {
        data: attributeCombinations,
        refetch: refetchAttributeCombinations,
        isFetching,
        dataUpdatedAt: attributeCombinationKey,
    } = useQuery<CombinationTableItem[]>({
        queryKey: ["listing/attribute-combination"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/attribute-combination", {
                    method: "asc",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                    page: 1,
                })
                .then((data) =>
                    data.entities.map((el) => {
                        return {
                            id: el.id,
                            attributes: getFieldValue!<Attribute[]>(
                                el.fields,
                                "attributes"
                            )!.map((el) => ({
                                id: el.id,
                                name: el.name,
                            })),
                        }
                    })
                ),
        initialData: [],
    })

    const onChangeOrder = useCallback(
        (rowId: Uuid, value: number) => {
            const deletePart = value ? "" : "/delete"
            const method = (value ? api.put : api.post).bind(api)
            const requestData = {
                group: activeGroup.value,
                producer: id,
                region: activeRegion.value,
                attributeCombination: rowId,
                value: value || undefined,
            }
            method(
                "v1/economics/producer/attribute-combination-order" +
                deletePart,
                {},
                requestData
            )
                .then(() => {
                    refetchCombinatorOrders()
                    showNotification({
                        type: "success",
                        message: "Успех",
                    })
                })
                .catch(handleApiError)
                .finally(refetchCombinatorOrders)
        },
        [api]
    )

    const saveOrder =
        (rowId: Uuid) =>
            (e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
                const value = +e.currentTarget.value
                if (focusRef.current === value) return
                const isExists = combinationOrders.some((el) => el.order === value)
                console.log(isExists)
                if (isExists) {
                    e.currentTarget.classList.add("error-input")
                } else {
                    e.currentTarget.classList.remove("error-input")
                    onChangeOrder(rowId, value)
                }
            }

    const refetchAll = useCallback(() => {
        refetchAttributeCombinations()
        refetchCombinatorOrders()
    }, [refetchAttributeCombinations, refetchCombinatorOrders])

    return (
        <Flex.Col fullWidth gap={20} className="--visible">
            <Typography.Title level={5} className="fw text-align-start mb-2">
                Комбинации свойств
            </Typography.Title>
            <UTable
                key={`${dataUpdatedAt}-${attributeCombinationKey}`}
                data={attributeCombinations}
                loading={isFetching || fetchCombinatinOrders}
                isRowActive={(row) => selectedRow?.id === row.id}
                onRowClick={(row) =>
                    setSelectedRow((prev) => (prev?.id === row.id ? null : row))
                }
                columns={[
                    {
                        columnName: "ID",
                        render: (row) => (
                            <span style={{ fontSize: 14 }}>{row.id}</span>
                        ),
                        width: 20,
                    },
                    {
                        columnName: "Комбинация",
                        render: (row) => (
                            <div
                                className="fw series-detail-combination-order"
                                style={{ overflowX: "auto" }}
                            >
                                <Flex.Row justify="start" fullWidth>
                                    {row.attributes.map((el) => (
                                        <Tag key={el.id}>{el.name}</Tag>
                                    ))}
                                </Flex.Row>
                            </div>
                        ),
                    },
                    {
                        columnName: "Порядок",
                        render: (row) => (
                            <InputNumber
                                key={`${dataUpdatedAt}-${attributeCombinationKey}`}
                                bordered={false}
                                controls={false}
                                onFocus={(e) => {
                                    focusRef.current = +e.target.value
                                    e.currentTarget.classList.remove(
                                        "error-input"
                                    )
                                }}
                                defaultValue={
                                    combinationOrders.find(
                                        (el) =>
                                            el.attributeCombination.id ===
                                            row.id
                                    )?.order
                                }
                                min={0}
                                onPressEnter={saveOrder(row.id)}
                                onBlur={saveOrder(row.id)}
                            />
                        ),
                        width: 10,
                    },
                ]}
            />
            <EntityCopyRegion
                entity="producer"
                activeRegionId={activeRegion?.value}
                itemsGroup={activeGroup}
                refetch={refetchAll}
            />
        </Flex.Col>
    )
}
